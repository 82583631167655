//@ts-nocheck

export default function ItemCard(props) {
  return (
    <div
      className={`h-24 w-24 flex justify-center items-center border-l-2 border-r-2 border-t-2 border-[#373762] backdrop-blur-sm shadow-purplestrong transition-all relative bg-item-card-bg-${props.rarity} bg-cover transition-all duration-500 hover:scale-110 group hover:shadow-purpleweak `}
    >
      <div className="p-4 relative z-20">
        <img
          className="h-16 group-hover:animate-pulse"
          src={props.image}
          alt=""
        />
      </div>
      {/* <div className="absolute bottom-0 h-[80%] w-full bg-gradient-radial z-10 bg-blend-multiply overflow-visible"></div> */}
    </div>
  );
}
