//@ts-nocheck
import React from "react";
import { useEffect } from "react";

export default function Newsletter() {
  return (
    <div className="flex flex-col gap-4 text-lg p-8 border-2 border-border backdrop-blur-sm transition-all duration-500 shadow-purpleweak hover:shadow-purplestrong mt-16 relative z-50">
      {/* <p className="text-2xl text-left font-bold">Want to know more?</p> */}
      <p>Drop your email below and we’ll keep you in the loop.</p>
      <form
        action="https://api.sheetmonkey.io/form/vmDBav2xASrMDVX6YoYVUH"
        method="post"
        className="flex gap-4 sm:flex-row flex-col relative z-50"
        id="newsletter-form"
      >
        <div className="flex gap-4  w-full">
          <input
            type="email"
            name="Email"
            placeholder="enter your email address"
            className="w-full border-2 border-border p-4 bg-transparent relative z-50 form-input"
            required
          />
          <input
            type="hidden"
            name="Created"
            value="x-sheetmonkey-current-date-time"
          />
        </div>
        <input
          type="submit"
          className="button w-full sm:w-fit"
          value="SUBMIT"
        />
      </form>
    </div>
  );
}
