//@ts-nocheck
import Atropos from "atropos/react";
import useMediaQuery from "beautiful-react-hooks/useMediaQuery";


export default function AtroposComp() {
  const isMobile = useMediaQuery("(max-width: 1024px)");

  if (isMobile) {
    return (
      <div
        id="atropos"
        className="xl:scale-[80%] lg:scale-[80%] md:scale-[80%] sm:scale-75 scale-[56%] backdrop-blur-lg shadow-purpleweak relative z-30 flex justify-center items-center"
      >
        <div className="h-[475px] w-[475px] flex justify-center items-center border-2 border-[#3c3b60] z-100 relative">
          <div
            className="max-h-[418px] max-w-[418px] min-h-[418px] min-w-[418px] h-[418px]
w-[418px] relative flex justify-center items-center aspect-square	"
          >
            <img
              className="absolute top-20 left-20 z-30"
              data-atropos-offset="10"
              src="/chest-1.png"
              alt=""
            />
            <img
              className="absolute top-0 right-20 z-30"
              data-atropos-offset="15"
              src="/chest-2.png"
              alt=""
            />
            <img
              className="absolute top-0"
              data-atropos-offset="4"
              src="/inner-lines.png"
              alt=""
            />
          </div>
        </div>
        <div className="z-20 absolute flex justify-center items-center w-full h-full top-0 left-0">
          <div className="w-[684px] h-[684px] absolute top-0 left-0 top: -top-[104px] -left-[105px] pointer-events-none">
            <img
              className="pointer-events-none"
              src="/background-grid.png"
              alt=""
            />
          </div>
          <div className="z-10 relative top-0 left-0 w-full h-full">
            <img
              src="/bottom-right.png"
              alt=""
              className="absolute -bottom-[12rem] left-80 z-10 w-[300px]"
              data-atropos-offset="17"
            />
            <img
              src="/bottom-left.png"
              alt=""
              className="absolute -bottom-40 right-80 z-10 w-[400px]"
              data-atropos-offset="15"
            />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      id="atropos"
      className="xl:scale-[80%] lg:scale-[80%] md:scale-[80%] sm:scale-75 scale-[56%]"
    >
      {/* Atropos */}
      <Atropos
        activeOffset={40}
        alwaysActive={true}
        eventsEl={"body"}
        shadow={false}
        highlight={false}
        className="my-atropos"
        innerClassName="backdrop-blur-lg shadow-purpleweak relative z-30 flex justify-center items-center"
        rotateClassName="relative"
        rotateChildren={
          <>
            <div className="z-20 absolute flex justify-center items-center w-full h-full top-0 left-0">
              <div className="w-[684px] h-[684px] absolute top-0 left-0 top: -top-[104px] -left-[105px] pointer-events-none">
                <img
                  className="pointer-events-none"
                  src="/background-grid.png"
                  alt=""
                />
              </div>
            </div>
            <div className="z-10 relative">
              <img
                src="/bottom-right.png"
                alt=""
                className="absolute -bottom-40 left-80 z-10 w-[300px]"
                data-atropos-offset="17"
              />
              <img
                src="/bottom-left.png"
                alt=""
                className="absolute -bottom-40 right-80 z-10 w-[400px]"
                data-atropos-offset="15"
              />
            </div>
          </>
        }
      >
        <div className="h-[475px] w-[475px] flex justify-center items-center border-2 border-[#3c3b60] z-100">
          <div
            className="max-h-[418px] max-w-[418px] min-h-[418px] min-w-[418px] h-[418px]
w-[418px] relative flex justify-center items-center aspect-square	"
          >
            <img
              className="absolute top-20 left-20 z-30"
              data-atropos-offset="10"
              src="/chest-1.png"
              alt=""
            />
            <img
              className="absolute top-0 right-20 z-30"
              data-atropos-offset="15"
              src="/chest-2.png"
              alt=""
            />
            <img
              className="absolute top-0"
              data-atropos-offset="4"
              src="/inner-lines.png"
              alt=""
            />
          </div>
        </div>
      </Atropos>
    </div>
  );
}
