//@ts-nocheck
import React from "react";
import ItemCard from "./ItemCard.tsx";

export default function ScrollingItems() {
  return (
    <>
      <div className="h-10 w-screen relative sm:top-[-20rem] top-[-2rem] z-10  pointer-events-none">
        <div className="border-b-[1px] border-t-[1px] border-[#3c3b60]  transform absolute top-[48px]  w-screen "></div>
        <div className="absolute primary-infinite-scroll flex gap-16 pl-16">
          <ItemCard image="/example-item-01.png" rarity="blue" />
          <ItemCard image="/example-item-02.png" rarity="purple" />
          <ItemCard image="/example-item-03.png" rarity="blue" />
          <ItemCard image="/example-item-04.png" rarity="green" />
          <ItemCard image="/example-item-05.png" rarity="orange" />
          <ItemCard image="/example-item-06.png" rarity="purple" />
          <ItemCard image="/example-item-07.png" rarity="green" />
          <ItemCard image="/example-item-08.png" rarity="orange" />
          <ItemCard image="/example-item-09.png" rarity="blue" />
          <ItemCard image="/example-item-10.png" rarity="purple" />
          <ItemCard image="/example-item-11.png" rarity="blue" />
          <ItemCard image="/example-item-12.png" rarity="green" />
          <ItemCard image="/example-item-13.png" rarity="orange" />
          <ItemCard image="/example-item-14.png" rarity="blue" />
          <ItemCard image="/example-item-01.png" rarity="blue" />
          <ItemCard image="/example-item-02.png" rarity="purple" />
          <ItemCard image="/example-item-03.png" rarity="blue" />
          <ItemCard image="/example-item-04.png" rarity="green" />
          <ItemCard image="/example-item-05.png" rarity="orange" />
          <ItemCard image="/example-item-06.png" rarity="purple" />
          <ItemCard image="/example-item-07.png" rarity="green" />
        </div>
        <div className="absolute secondary-infinite-scroll flex gap-16 pl-16">
          <ItemCard image="/example-item-01.png" rarity="blue" />
          <ItemCard image="/example-item-02.png" rarity="purple" />
          <ItemCard image="/example-item-03.png" rarity="blue" />
          <ItemCard image="/example-item-04.png" rarity="green" />
          <ItemCard image="/example-item-05.png" rarity="orange" />
          <ItemCard image="/example-item-06.png" rarity="purple" />
          <ItemCard image="/example-item-07.png" rarity="green" />
          <ItemCard image="/example-item-08.png" rarity="orange" />
          <ItemCard image="/example-item-09.png" rarity="blue" />
          <ItemCard image="/example-item-10.png" rarity="purple" />
          <ItemCard image="/example-item-11.png" rarity="blue" />
          <ItemCard image="/example-item-12.png" rarity="green" />
          <ItemCard image="/example-item-13.png" rarity="orange" />
          <ItemCard image="/example-item-14.png" rarity="blue" />
          <ItemCard image="/example-item-01.png" rarity="blue" />
          <ItemCard image="/example-item-02.png" rarity="purple" />
          <ItemCard image="/example-item-03.png" rarity="blue" />
          <ItemCard image="/example-item-04.png" rarity="green" />
          <ItemCard image="/example-item-05.png" rarity="orange" />
          <ItemCard image="/example-item-06.png" rarity="purple" />
          <ItemCard image="/example-item-07.png" rarity="green" />
        </div>
      </div>
    </>
  );
}
